.workExperienceColumn {
    background-color: #EEECE1;
    height: 22px;
    width: 22px;
    border-radius: 5px;
    border: 1px solid #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 10;
    box-shadow: 0px 4px 4px 0px #0000001A;

}

.workExperienceColumn::after {
    position: absolute;
    height: 12px;
    width: 2px;
    background-color: #1F497D;
    content: "";
    z-index: 0;
    bottom: -13px;
}

.workExperienceColumn::before {
    position: absolute;
    height: 10px;
    width: 10px;
    background-image: url('../assets/columnArrow.svg');
    background-repeat: no-repeat;
    background-size: 10px;
    content: "";
    bottom: -21px;
}

.verticalLine {
    position: relative;
}

.verticalLine::after {
    position: absolute;
    height: 13px;
    width: 2px;
    background-color: #1F497D;
    content: "";
    z-index: 0;
    top: -12px;
    left: 10.5px
}

table tr:first-child .verticalLine::after {
    display: none
}

table tr:last-child .workExperienceColumn::after {
    display: none
}

table tr:last-child .workExperienceColumn::before {
    display: none
}

::-webkit-scrollbar {
    width: '6px'
}

::-webkit-scrollbar-thumb {
    background-color: "#6C96C1";
    border-radius: '6px';
}

::-webkit-scrollbar-track {
    background-color: "#D9D9D9";
}
